<template>
  <div class="container account-settings-container m-b-110">
    <div class="card p-1 text-first  border-0">
      <div class="card-body mx-auto">
        <h2 class="fw-bold">アカウント設定</h2>
        <div class="row">
          <h4 class="mb-4 mt-4">基本情報</h4>
          <div class="col-5">
            <label>姓</label>
            <input v-model="lastName" class="form-control" disabled name="inputSurname" type="text">
          </div>
          <div class="col-5">
            <label>名</label>
            <input v-model="firstName" class="form-control" disabled name="inputName" type="text">
          </div>
          <div class="col-2 p-0" @click="$router.push('editname')">
            <p class="required-text">変更</p>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-10">
            <label>生年月日</label>
            <input v-model="birthday" class="form-control" disabled name="inputBirthday" type="text">
          </div>
          <div class="col-2 p-0" @click="$router.push('editbirthday')">
            <p class="required-text">変更</p>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-10">
            <label>パスワード</label>
            <input class="form-control" disabled name="inputPassword" placeholder="●●●●●●●●●●●" type="password">
          </div>
          <div class="col-2 p-0" @click="$router.push('editpasswordrequired')">
            <p class="required-text">変更</p>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-10">
            <label>性別</label>
            <input v-model="gender" class="form-control" disabled name="inputGender" type="text">
          </div>
          <div class="col-2 p-0" @click="$router.push('editsex')">
            <p class="required-text">変更</p>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-10">
            <label>国籍</label>
            <input v-model="nationality" class="form-control" disabled name="inputCountryOfCitizenship" type="text">
          </div>
          <div class="col-2 p-0" @click="$router.push('editcountry')">
            <p class="required-text">変更</p>
          </div>
        </div>
        <div class="row">
          <h4 class="mb-4 mt-5">連絡先情報</h4>
          <div class="col">
            <label>メールアドレス</label>
            <input v-model="email" class="form-control" disabled name="inputMailAddress" type="email">
          </div>
          <div class="col-2 p-0" @click="$router.push('editmailaddress')">
            <p class="required-text">変更</p>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <label>住所</label>
            <input v-model="address" class="form-control" disabled name="inputStreetAddress" type="text">
          </div>
          <div class="col-2 p-0" @click="$router.push('editaddress')">
            <p class="required-text">変更</p>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <label>電話番号</label>
            <input v-model="phoneNo" class="form-control" disabled name="inputPhoneNumber" type="text">
          </div>
          <div class="col-2 p-0" @click="$router.push('editphone')">
            <p class="required-text">変更</p>
          </div>
        </div>
        <!--
        <div class="row">
          <h4 class="mb-4 mt-5">その他</h4>
          <label>新型コロナワクチン接種</label>
          <div class="col-5">
            <input v-model="coronaVaccinationCount" class="form-control" disabled name="inputBasicInformationSaito"
                   type="text">
          </div>
          <div class="col-5">
            <input v-model="latestCoronaVaccinationDate" class="form-control" disabled
                   name="inputBasicInformationMinatsu" type="text">
          </div>
          <div class="col-2 p-0"
               style="margin-top: -1.4rem;"
               @click="$router.push({ name : 'VaccineChange', params : { vaccinationData : vaccinationData } })">
            <p class="required-text">変更</p>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-5">
            <label>PCR検査</label>
            <input v-model="latestPcrTestResult" class="form-control" disabled name="inputPCRTestSaito" type="text">
          </div>
          <div class="col-5">
            <label>&nbsp;</label>
            <input v-model="latestPcrTestDate" class="form-control" disabled name="inputPCRTestMinatsu" type="text">
          </div>
          <div class="col-2 p-0" @click="$router.push({ name: 'PcrTestChange', params: { pcrTestData: pcrTestData } })">
            <p class="required-text">変更</p>
          </div>
        </div>
        -->
        <div class="row mt-4 medical-history">
          <label>既往歴について</label>
          <div class="col-10">
            <div class="form-check ml-2 mx-auto m-t-10">
              <input
                  id="anemia"
                  v-model="anemia"
                  class="form-check-input"
                  disabled
                  type="checkbox"
              />
              <label class="form-check-label" for="anemia">貧血</label>
            </div>
            <div class="form-check ml-2 mx-auto m-t-10">
              <input
                  id="asthma"
                  v-model="asthma"
                  class="form-check-input"
                  disabled
                  type="checkbox"
              />
              <label class="form-check-label" for="asthma">ぜんそく</label>
            </div>
            <div class="form-check ml-2 mx-auto m-t-10">
              <input
                  id="heartDisease"
                  v-model="heartDisease"
                  class="form-check-input"
                  disabled
                  type="checkbox"
              />
              <label class="form-check-label" for="heartDisease">心臓病</label>
            </div>
            <div class="form-check ml-2 mx-auto m-t-10">
              <input
                  id="kidneyDisease"
                  v-model="kidneyDisease"
                  class="form-check-input"
                  disabled
                  type="checkbox"
              />
              <label class="form-check-label" for="kidneyDisease">腎臓病</label>
            </div>
            <div class="form-check ml-2 mx-auto m-t-10">
              <input
                  id="epilepsy"
                  v-model="epilepsy"
                  class="form-check-input"
                  disabled
                  type="checkbox"
              />
              <label class="form-check-label" for="epilepsy">てんかん</label>
            </div>
            <div class="form-check ml-2 mx-auto m-t-10">
              <input
                  id="highBloodPressure"
                  v-model="highBloodPressure"
                  class="form-check-input"
                  disabled
                  type="checkbox"
              />
              <label class="form-check-label" for="highBloodPressure">高血圧</label>
            </div>
            <div class="form-check ml-2 mx-auto m-t-10">
              <input
                  id="diabetes"
                  v-model="diabetes"
                  class="form-check-input"
                  disabled
                  type="checkbox"
              />
              <label class="form-check-label" for="diabetes">糖尿病</label>
            </div>
            <div class="form-check ml-2 mx-auto m-t-10">
              <input
                  id="medicalHistoryOther"
                  v-model="other"
                  class="form-check-input"
                  disabled
                  type="checkbox"
              />
              <label class="form-check-label" for="medicalHistoryOther">その他</label>
            </div>

            <div v-if="other" class="form-check p-l-0 mx-auto">
              <textarea id="otherText" v-model="otherText" class="form-control" disabled rows="2"></textarea>
              <div v-show="errors['otherText']" id="err_other" class="form-error">{{ errors['otherText'] }}</div>
            </div>

          </div>
          <div class="col-2 p-0 medical-history-change"
               @click="$router.push({ name: 'EditMedicalHistory' })">
            <p class="required-text">変更</p>
          </div>
        </div>

        <div class="account-delete-btn mt-5 text-left">
          <button class="btn btn-dark" type="button" v-on:click="$router.push('DeleteAccount')">アカウント削除</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Common from 'jsadminclient/common'

export default {
  name: "AccountSettings",
  data() {
    return {
      lastName: '',
      firstName: '',
      birthday: '',
      gender: '',
      nationality: "",
      email: '',
      address: '',
      phoneNo: '',
      medicalHistory: '',
      hasCoronaVaccination: "",
      coronaVaccinationCount: null,
      latestCoronaVaccinationDate: '',
      latestPcrTestResult: "",
      latestPcrTestDate: '',
      anemia: null,
      asthma: null,
      heartDisease: null,
      kidneyDisease: null,
      epilepsy: null,
      highBloodPressure: null,
      diabetes: null,
      other: null,
      otherText: null,
      vaccinationData: {},
      pcrTestData: {},
      errors: []
    }
  },
  computed: {
    ...mapGetters(['config']),
  },
  methods: {
    async getUser() {
      const user_id = this.config.userId
      const token = this.config.token
      try {
        const api = Common.createUserApi(token)
        const jsonObject = await api.usersUserIdGetAsync(user_id)
        this.firstName = jsonObject.firstName
        this.lastName = jsonObject.lastName
        this.birthday = jsonObject.birthday
        this.gender = Common.getGender(jsonObject.gender)
        this.nationality = jsonObject.nationalityName
        this.email = jsonObject.email
        this.address = jsonObject.address
        this.phoneNo = jsonObject.phoneNo
        this.vaccinationData = {
          hasCoronaVaccination: jsonObject.hasCoronaVaccination,
          coronaVaccinationCount: jsonObject.coronaVaccinationCount,
          latestCoronaVaccinationDate: jsonObject.latestCoronaVaccinationDate
        }
        this.pcrTestData = {
          latestPcrTestResult: jsonObject.latestPcrTestResult,
          latestPcrTestDate: jsonObject.latestPcrTestDate
        }
        this.coronaVaccinationCount = Common.getHasCoronaVaccination1(jsonObject.coronaVaccinationCount)
        if(jsonObject.coronaVaccinationCount!=0){
          this.latestCoronaVaccinationDate = jsonObject.latestCoronaVaccinationDate
        }else{
          this.latestCoronaVaccinationDate = null
        }
        this.latestPcrTestResult = Common.getLatestPcrTestResult(jsonObject.latestPcrTestResult)
        this.latestPcrTestDate = jsonObject.latestPcrTestDate
        this.anemia = jsonObject.anemia
        this.asthma = jsonObject.asthma
        this.heartDisease = jsonObject.heartDisease
        this.kidneyDisease = jsonObject.kidneyDisease
        this.epilepsy = jsonObject.epilepsy
        this.highBloodPressure = jsonObject.highBloodPressure
        this.diabetes = jsonObject.diabetes
        this.other = jsonObject.other
        this.otherText = jsonObject.otherText
      } catch (error) {
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(error)
        }
      }
    },
  },
  mounted() {
    this.getUser()
  }
}
</script>

<style lang="scss" scoped>
.account-settings-container {
  padding-left: 0;
  padding-right: 0;
}

.account-delete-btn {
  margin-bottom: 6rem;
}

.btn-dark {
  background: #333333;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 16px;
  height: 44px;
}

.required-text {
  color: #C95D6C;
  margin-top: 2.2rem !important;
  font-weight: bold;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  cursor: pointer;
}

input[type="text"] {
  background-color: #EFF2F7;
  border: 1px solid #EFF2F7;
  height: 48px;
}

.medical-history-change {
  display: flex;
  align-items: center;
  justify-content: center;
}

select {
  background-color: #EFF2F7;
}

label {
  font-size: 16px;
  color: #333333;
}

@media only screen and (max-width: 576px) {

  .account-settings-container {
    margin-top: -30px;
  }

  .card {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0;
  }

}

.form-check {
  &-input {
    margin-top: 6px;
  }

  &-input:disabled, &-input[disabled], &-input:disabled ~ &-label, &-input[disabled] ~ &-label {
    opacity: 1;
  }
}
</style>
